<template>
  <el-dialog
    title="View the details of the molecule"
    :visible="dialogoDetailVisible"
    width="70rem"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-descriptions title="Basic information about the molecule" :column="2" border>
      <el-descriptions-item label="Name">{{
        form.name
      }}</el-descriptions-item>
      <el-descriptions-item label="Formula">{{
        form.molecular_formula
      }}</el-descriptions-item>
      <el-descriptions-item label="CAS">{{
        form.cas_number == "" ? "-" : form.cas_number
      }}</el-descriptions-item>
      <el-descriptions-item label="CID">{{
        form.pub_chem_cid == "" ? "-" : form.pub_chem_cid
      }}</el-descriptions-item>
      <el-descriptions-item label="Last updated">
        <el-tag type="warning">{{
          timestampToTime(form.edit_time)
        }}</el-tag></el-descriptions-item
      >
      <el-descriptions-item label="Status">
        <el-tag
          :type="
            form.status == 1
              ? 'info'
              : form.status == 2
              ? 'success'
              : form.status == 3
              ? 'warning'
              : 'danger'
          "
          >{{
            form.status == 1
              ? "ToBeReviewed"
              : form.status == 2
              ? "Passed"
              : form.status == 3
              ? "Unpassed"
              : "Offline"
          }}</el-tag
        ></el-descriptions-item
      >
    </el-descriptions>
    <el-card class="propertie_card">
      <div slot="header"><b>Molecular properties</b></div>
      <div class="propertie_pannel" v-html="form.content"></div>
    </el-card>
    <el-descriptions title="Notes" :span="1">
      <el-descriptions-item label="Notes content"
        ><el-input
              type="textarea"
              readonly
              :autosize="{ minRows: 1, maxRows: 4 }"
              v-model="form.remark"
              maxlength="255"
              show-word-limit
            >
            </el-input></el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="Review information" :column="3" border>
      <el-descriptions-item label="Review opinions">{{
        auditDetailInfo.remark
      }}</el-descriptions-item>
      <el-descriptions-item label="Reviewers">{{
        auditDetailInfo.user_name
      }}</el-descriptions-item>
      <el-descriptions-item label="Review time">{{
        timestampToTime(auditDetailInfo.create_time)
      }}</el-descriptions-item>
    </el-descriptions>
    <div slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        plain
        @click="handleCancel()"
        icon="el-icon-circle-close"
        >Close</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "PropertieRecordShowDetail",
  data() {
    return {
      form: {},
      dialogoDetailVisible: false,
      //审核信息
      auditDetailInfo: {},
    };
  },
  methods: {
    handleOpen(data) {
      this.form = data;
      this.dialogoDetailVisible = true;
      this.getAuditDetailInfo();
    },
    handleCancel() {
      this.form = {};
      this.dialogoDetailVisible = false;
    },
    getAuditDetailInfo() {
      this.$store
        .dispatch("propertieRecordAuditRecord/selectDetail", {
          id: this.form.propertie_record_id,
        })
        .then((res) => {
          this.auditDetailInfo = res.data;
        });
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style scoped>
.propertie_pannel {
  height: 15rem;
  overflow-y: overlay !important;
  overflow-x: hidden !important;
}
.propertie_card{
  margin-bottom: 10px;
}
.propertie_card::v-deep .el-card__body {
  padding: 10px !important;
}
</style>