<template>
  <div class="pannel">
    <el-card>
      <el-table :data="infoList" style="width: 100%">
        <el-table-column prop="name" label="Name" width="180">
        </el-table-column>
        <el-table-column prop="molecular_formula" label="Formula" width="180">
        </el-table-column>
        <el-table-column prop="cas_number" label="CAS"></el-table-column>
        <el-table-column prop="pub_chem_cid" label="CID"></el-table-column>
        <el-table-column label="Properties/Activities" width="180">
          <template slot-scope="scope">
            <el-popover placement="top-start" width="500" trigger="hover">
              <div v-html="scope.row.content"></div>
              <el-tag slot="reference">View</el-tag>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="Notes" width="80">
          <template slot-scope="scope">
            <el-popover placement="top-start" width="500" trigger="hover">
              <div v-html="scope.row.remark"></div>
              <el-tag v-if="scope.row.remark != ''" slot="reference">View</el-tag>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="edit_time" label="Last updated">
          <template slot-scope="scope">
            <span>{{ timestampToTime(scope.row.edit_time) }}</span>
          </template></el-table-column
        >
        <el-table-column prop="status" label="Status" width="115"
          ><template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status == 1
                  ? 'info'
                  : scope.row.status == 2
                  ? 'success'
                  : scope.row.status == 3
                  ? 'warning'
                  : 'danger'
              "
              >{{
                scope.row.status == 1
                  ? "ToBeReviewed"
                  : scope.row.status == 2
                  ? "Passed"
                  : scope.row.status == 3
                  ? "Unpassed"
                  : "Offline"
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="Operatiion" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-view"
              @click="handleShowDetail(scope.row)"
              v-if="scope.row.status != 1"
              >View</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <PropertieRecordShowDetail ref="PropertieRecordShowDetail" />
  </div>
</template>

<script>
import PropertieRecordShowDetail from "../PropertieRecordShowDetail/index.vue";
export default {
  components: { PropertieRecordShowDetail },
  data() {
    return {
      loading: false,
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    //获取信息
    getInfo() {
      this.loading = true;
      this.$store
        .dispatch("propertieRecord/userSelect", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
        })
        .then((res) => {
          const { data } = res;
          this.total = data.total;
          this.infoList = data.info_list;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = true;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //查看详情
    handleShowDetail(row) {
      this.$refs.PropertieRecordShowDetail.handleOpen(row)
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>

<style scoped>
::v-deep .el-card__body {
  padding: 10px !important;
}
</style>