var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pannel"},[_c('el-card',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.infoList}},[_c('el-table-column',{attrs:{"prop":"name","label":"Name","width":"180"}}),_c('el-table-column',{attrs:{"prop":"molecular_formula","label":"Formula","width":"180"}}),_c('el-table-column',{attrs:{"prop":"cas_number","label":"CAS"}}),_c('el-table-column',{attrs:{"prop":"pub_chem_cid","label":"CID"}}),_c('el-table-column',{attrs:{"label":"Properties/Activities","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"top-start","width":"500","trigger":"hover"}},[_c('div',{domProps:{"innerHTML":_vm._s(scope.row.content)}}),_c('el-tag',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v("View")])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Notes","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"top-start","width":"500","trigger":"hover"}},[_c('div',{domProps:{"innerHTML":_vm._s(scope.row.remark)}}),(scope.row.remark != '')?_c('el-tag',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v("View")]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"edit_time","label":"Last updated"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.timestampToTime(scope.row.edit_time)))])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"Status","width":"115"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tag',{attrs:{"type":scope.row.status == 1
                ? 'info'
                : scope.row.status == 2
                ? 'success'
                : scope.row.status == 3
                ? 'warning'
                : 'danger'}},[_vm._v(_vm._s(scope.row.status == 1 ? "ToBeReviewed" : scope.row.status == 2 ? "Passed" : scope.row.status == 3 ? "Unpassed" : "Offline"))])]}}])}),_c('el-table-column',{attrs:{"label":"Operatiion","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status != 1)?_c('el-button',{attrs:{"size":"mini","type":"primary","icon":"el-icon-view"},on:{"click":function($event){return _vm.handleShowDetail(scope.row)}}},[_vm._v("View")]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"page-sizes":[5, 10, 15, 20],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('PropertieRecordShowDetail',{ref:"PropertieRecordShowDetail"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }