var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"View the details of the molecule","visible":_vm.dialogoDetailVisible,"width":"70rem","close-on-click-modal":false,"show-close":false}},[_c('el-descriptions',{attrs:{"title":"Basic information about the molecule","column":2,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"Name"}},[_vm._v(_vm._s(_vm.form.name))]),_c('el-descriptions-item',{attrs:{"label":"Formula"}},[_vm._v(_vm._s(_vm.form.molecular_formula))]),_c('el-descriptions-item',{attrs:{"label":"CAS"}},[_vm._v(_vm._s(_vm.form.cas_number == "" ? "-" : _vm.form.cas_number))]),_c('el-descriptions-item',{attrs:{"label":"CID"}},[_vm._v(_vm._s(_vm.form.pub_chem_cid == "" ? "-" : _vm.form.pub_chem_cid))]),_c('el-descriptions-item',{attrs:{"label":"Last updated"}},[_c('el-tag',{attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.timestampToTime(_vm.form.edit_time)))])],1),_c('el-descriptions-item',{attrs:{"label":"Status"}},[_c('el-tag',{attrs:{"type":_vm.form.status == 1
            ? 'info'
            : _vm.form.status == 2
            ? 'success'
            : _vm.form.status == 3
            ? 'warning'
            : 'danger'}},[_vm._v(_vm._s(_vm.form.status == 1 ? "ToBeReviewed" : _vm.form.status == 2 ? "Passed" : _vm.form.status == 3 ? "Unpassed" : "Offline"))])],1)],1),_c('el-card',{staticClass:"propertie_card"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('b',[_vm._v("Molecular properties")])]),_c('div',{staticClass:"propertie_pannel",domProps:{"innerHTML":_vm._s(_vm.form.content)}})]),_c('el-descriptions',{attrs:{"title":"Notes","span":1}},[_c('el-descriptions-item',{attrs:{"label":"Notes content"}},[_c('el-input',{attrs:{"type":"textarea","readonly":"","autosize":{ minRows: 1, maxRows: 4 },"maxlength":"255","show-word-limit":""},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1),_c('el-descriptions',{attrs:{"title":"Review information","column":3,"border":""}},[_c('el-descriptions-item',{attrs:{"label":"Review opinions"}},[_vm._v(_vm._s(_vm.auditDetailInfo.remark))]),_c('el-descriptions-item',{attrs:{"label":"Reviewers"}},[_vm._v(_vm._s(_vm.auditDetailInfo.user_name))]),_c('el-descriptions-item',{attrs:{"label":"Review time"}},[_vm._v(_vm._s(_vm.timestampToTime(_vm.auditDetailInfo.create_time)))])],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary","plain":"","icon":"el-icon-circle-close"},on:{"click":function($event){return _vm.handleCancel()}}},[_vm._v("Close")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }